import React from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";

import defaultStyles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import { ThemeBox } from "../ThemeBox";
import { themeData } from "../../utils/themeData";
import { makeRNCompatibleStyleArr } from "../../utils/makeRNCompatibleStyleArr";
import COLORS from "../../utils/colors";

interface ButtonProps {
  loading?: boolean;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  gradientColors?: [string, string];
  activityIndicatorColor?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  onPress?: () => void;
}

interface ButtonState {}

export default class Button extends ThemeBox<ButtonProps, ButtonState> {
  constructor(props) {
    super(props);
  }

  color: (typeof themeData)[keyof typeof themeData]["color"] =
    themeData[this.props.theme]["color"];

  activityIndicatorColor =
    themeData[this.props.theme]["activityIndicatorColor"];

  ButtonBase = () => {
    const { loading, text, textStyle, icon, iconPosition } = this.props;

    const finalTextStyle = makeRNCompatibleStyleArr([
      defaultStyles.text,
      { color: this.color + `${loading ? "70" : ""}` },
      textStyle,
    ]);

    const flexDirection = iconPosition === "right" ? "row-reverse" : "row";

    return (
      <View style={[defaultStyles.innerView]}>
        <View
          style={{
            display: "flex",
            flexDirection,
            alignItems: "center",
          }}
        >
          <Text style={finalTextStyle}>{text}</Text>
          {icon}
        </View>
        {loading && (
          <ActivityIndicator
            color={this.activityIndicatorColor}
            style={{ paddingLeft: 7 }}
          />
        )}
      </View>
    );
  };

  ButtonWithGradient(props) {
    let { children, disabled, activityIndicatorColor, ...restProps } = props;

    const gradientColors =
      props.gradientColors || themeData["primary"]["gradientColors"];

    return (
      <View>
        <TouchableOpacity disabled={disabled} {...restProps}>
          <LinearGradientComponent
            style={defaultStyles.gradient}
            colors={gradientColors}
          >
            <this.ButtonBase />
          </LinearGradientComponent>
        </TouchableOpacity>
      </View>
    );
  }

  Button(props) {
    let { disabled, ...restProps } = props;

    return (
      <View>
        <TouchableOpacity disabled={disabled} {...restProps}>
          <this.ButtonBase />
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    const {
      theme,
      activityIndicatorColor,
      disabled = false,
      style,
      ...restProps
    } = this.props;

    const styleTemporary = { height: 44 };

    const themedProps = {
      disabled,
      style: makeRNCompatibleStyleArr([
        {
          ...styleTemporary,
          ...defaultStyles[theme],
        },
        style,
        disabled && defaultStyles.disable,
      ]),
      ...restProps,
    };

    if (activityIndicatorColor)
      this.activityIndicatorColor = activityIndicatorColor;

    if (disabled) {
      this.color = COLORS.COLOR_DISABLE_GREY;
      return this.Button(themedProps);
    } else if (theme === "primary") {
      return this.ButtonWithGradient(themedProps);
    } else {
      return this.Button(themedProps);
    }
  }
}
