const STRINGS = {
  // strings for Popover component
  KEEP_ACCOUNT: 'Keep account',
  DELETE_ACCOUNT: 'Delete account',
  NAME: 'Name',
  EMAIL_ADDRESS: 'Email Address',
  CANCEL: 'Cancel',
  DONE: 'Done',
  DEFAULT_TXT_POPOVR_1:
    'Are you sure you want to delete Alice Smith’s account?',
  DEFAULT_TXT_POPOVR_2:
    'This action can’t be undone and all the data stored in this account won’t be accessible anymore.',
  POPOVER_TITLE: 'Popover title',
  POPOVER_INPUT_TEXT:
    'Fill in this form and we’ll get back to you as soon as possible!',

  // strings for chart component
  STACKED_BARCHART_TESTID: 'stacked-bar-chart',
  LEGEND_COLOR_TESTID: 'legend-color',
  BASIC_AREA_CHART_TESTID: 'basic-area-chart',
  LEGEND_ITEM_TESTID: 'legend-item',
  PIE_CHART_TESTID: 'pie-charts',
  SPLINE_CHART_TESTID: 'spline-area-chart',
  LEFT_LABEL_TEXT: 'Category',
  RIGHT_LABEL_TEXT: 'Views',
  PROGRESSBAR_TESTID: 'progressBar',
  DATAROW_TESTID: 'dataRow',
  LOG_IN: 'LOG IN',

  // strings for context menu component
  CONTEXTMENU_TESTID: 'context-menu',
  MENU_TESTID: 'menu',

  // string for data table component
  HEADER_DATA: 'header-data',
  TABLE_DATA: 'table-data',
  ACTION_BTN: 'action-btn',

  // strings for button component
  BUTTON_COMPONENT: 'btnComponent',
  DEFAULT_BTN_TXT: 'My Button',
  // strings for bottom tab component
  HEADER_BUTTON: 'headerBtn',
  BOTTOM_TAB: 'BottomTab',
  // strings for breadcrumbs component
  BTN_TESTID: 'page_number',
  TOAST_AVATAR: 'Toast_avatar',
  TOAST_CLOSE:'Toast_close',
  TOAST_BTN :'Toast_btn',

  // strings for pagination component
  PAGE_BTN_TESTID: 'page_btn',
  LEFT_BTN_TESTID:'leftArrow_btn',
  RIGHT_BTN_TESTID:'rightArrow_btn',

  SYSTEM_MSG_ICON :'System_msg_Icon'
}
export default STRINGS
