import React, { useContext, useEffect, useRef } from "react";
import { BuilderContext } from "../BuilderProvider/Builder.context";

const OutsideClickHandler = ({ children, onPress }) => {
  const isMountRef = useRef(true);
  const state = useContext(BuilderContext);
  const { newClick, providerChecker } = state;

  useEffect(() => {
    if (isMountRef.current) {
      isMountRef.current = false;
      return;
    }

    onPress && typeof onPress === "function" && onPress();
  }, [newClick]);

  const ProviderChecker = providerChecker;

  return <ProviderChecker>{children}</ProviderChecker>;
}

export default OutsideClickHandler