export function makeRNCompatibleStyleArr(styleArr: any[]): any[] {
  return styleArr.reduce(
    (all, cur) =>
      cur
        ? Array.isArray(cur)
          ? all.push(...cur) && all
          : all.push(cur) && all
        : all,
    []
  );
}
